import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Message = makeShortcode("Message");
const Contacts = makeShortcode("Contacts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "960px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.083333333333336%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAWyPCPFH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAIQMREjH/2gAIAQEAAQUCFRvWzaJai5hXof/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIBBQAAAAAAAAAAAAAAAAABESIxMmGB/9oACAEBAAY/AiVSzFMnR0Tc2P/EABwQAQEBAAIDAQAAAAAAAAAAAAERACFBUWGBsf/aAAgBAQABPyHnsv662U7KHOTk8BsuQfYWaWz0+YUlPR3/2gAMAwEAAgADAAAAECPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHhABAQABBAMBAAAAAAAAAAAAAREAITFBUWFxkfD/2gAIAQEAAT8QIMEklDDqkgqThbfmHgdLsyptPGB6KhfWEhXRfx25DaF0Dd4z/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "iprofiler big",
        "title": "iprofiler big",
        "src": "/static/a8174594621eb9266189321738fbd5ec/18e3b/iprofiler_big.jpg",
        "srcSet": ["/static/a8174594621eb9266189321738fbd5ec/46946/iprofiler_big.jpg 240w", "/static/a8174594621eb9266189321738fbd5ec/55489/iprofiler_big.jpg 480w", "/static/a8174594621eb9266189321738fbd5ec/18e3b/iprofiler_big.jpg 960w", "/static/a8174594621eb9266189321738fbd5ec/60e21/iprofiler_big.jpg 1440w", "/static/a8174594621eb9266189321738fbd5ec/69b48/iprofiler_big.jpg 1920w", "/static/a8174594621eb9266189321738fbd5ec/3ab01/iprofiler_big.jpg 3648w"],
        "sizes": "(max-width: 960px) 100vw, 960px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Offriamo servizi quali optometria comportamentale, valutazione della postura, terapie per riequilibrare la coordinazione tra visione e processi percettivi, allenamenti per l'efficienza visiva.`}</p>
    <p>{`L'obiettivo non è solo una buona vista ma una corretta visione che si traduce in comfort Visuo-Posturale.`}</p>
    <Message mdxType="Message">
      <p>{`Si eseguono valutazioni Visuo-Posturali, Analisi Visiva (O.E.P., MKH secondo Haase) e Visual Training su appuntamento.`}</p>
      <p>{`Per qualsiasi informazione non esitare a contattarci:`}</p>
      <br />
      <Contacts mdxType="Contacts" />
    </Message>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      